import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDTextField from "../../UI/MDTextField/MDTextField";
import { ONLY_NUMBERS_VALIDATION_REGEX } from "../../_constants_/constants";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import BudgetSplitPerBrand from "./BudgetSplitPerBrand";
import { setBudget } from "../../_reducers_/FileCardSlice";

interface PropType {
  errors: any;
  control: any;
}

const Budget = (props: PropType): ReactElement<PropType> => {
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const dispatch = useDispatch();
  const { t } = useTranslation("general");
  const [t_cc] = useTranslation(fileCard.fileCard.companyCode.toString());

  return (
    <div>
      <h2>{t("budget.headline")}</h2>
      {t_cc("html.budget-text") === "html.budget-text" ? undefined : <p>{t_cc("html.budget-text")}</p>}
      {fileCard.fileCard.budgetSplitPerBrand != null && fileCard.fileCard.budgetSplitPerBrand.length !== 0 ? (
        <BudgetSplitPerBrand control={props.control} errors={props.errors} />
      ) : (
        <MDTextField
          label={t("budget.budgetAmount")}
          name="budgetAmount"
          error={props.errors.budgetAmount}
          control={props.control}
          rules={{
            min: {
              message: t("validation.minNumberDigits", {
                number: 0,
              }),
              value: 0,
            },
            max: {
              message: t("validation.maxNumberDigits", {
                number: 2147483647,
              }),
              value: 2147483647,
            },
            pattern: {
              value: ONLY_NUMBERS_VALIDATION_REGEX,
              message: t("validation.canOnlyHoldNumbers"),
            },
          }}
          value={fileCard.fileCard.budget != null ? String(fileCard.fileCard.budget) : ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setBudget(e === "" ? null : e));
          }}
        />
      )}
    </div>
  );
};

export default Budget;
