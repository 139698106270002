import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDPhoneNumberField from "../../UI/MDPhoneNumberField/MDPhoneNumberField";
import MDTextField from "../../UI/MDTextField/MDTextField";
import { EMAIL_VALIDATION_REGEX } from "../../_constants_/constants";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import {
  setLogisticContactEmail,
  setLogisticContactName,
  setLogisticContactPhoneNumber,
  setLogisticContactPhoneNumberCallingCode,
} from "../../_reducers_/FileCardSlice";

interface PropType {
  errors: any;
  control: any;
  trigger: any;
  formState: any;
  setValue: any;
}

const LogisticContact = (props: PropType): ReactElement<PropType> => {
  const dispatch = useDispatch();
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const [t] = useTranslation("general");

  const validateEmail = (value: string) => {
    const re = EMAIL_VALIDATION_REGEX;
    const validationText: string = t("validation.wrongEmailFormat");
    return re.test(String(value).toLowerCase()) ? true : validationText;
  };
  return (
    <div>
      <h2>{t("logisticContact.headline")}</h2>
      <MDTextField
        label={t("logisticContact.contactPerson")}
        name="logisticContactContactPerson"
        error={props.errors.logisticContactContactPerson}
        control={props.control}
        rules={{
          required: { message: t("validation.requiredField"), value: true },
        }}
        value={fileCard.fileCard.logisticContact?.name ? fileCard.fileCard.logisticContact.name : ""}
        onChangeSetValue={(e: string): void => {
          dispatch(setLogisticContactName(e));
        }}
      />
      <MDPhoneNumberField
        setValue={props.setValue}
        phoneNumberRequired={true}
        callingCodeError={props.errors.logisticContactPhoneNumberCallingCode}
        callingCodeName="logisticContactPhoneNumberCallingCode"
        callingCodeValue={fileCard.fileCard.logisticContact?.phoneNumber?.callingCode ?? ""}
        callingCodeOnChange={(e: string): void => {
          dispatch(setLogisticContactPhoneNumberCallingCode(e));
        }}
        control={props.control}
        phoneNumberError={props.errors.logisticContactPhoneNumber}
        phoneNumberName="logisticContactPhoneNumber"
        phoneNumberLabel={t("logisticContact.phonenumber")}
        phoneNumberOnChange={(e: string): void => {
          dispatch(setLogisticContactPhoneNumber(e));
        }}
        phoneNumberValue={fileCard.fileCard.logisticContact?.phoneNumber?.phoneNumber ?? ""}
        trigger={props.trigger}
        formState={props.formState}
      />
      <MDTextField
        label={t("logisticContact.email")}
        name="logisticContactEmail"
        error={props.errors.logisticContactEmail}
        control={props.control}
        rules={{
          required: { value: true, message: t("validation.requiredField") },
          validate: validateEmail,
        }}
        value={fileCard.fileCard.logisticContact?.email ?? ""}
        onChangeSetValue={(e: string): void => {
          dispatch(setLogisticContactEmail(e));
        }}
      />
    </div>
  );
};

export default LogisticContact;
