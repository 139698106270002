import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDDropDown, { DropDownItem } from "../../UI/MDDropDown/MDDropDown";
import MDTextField from "../../UI/MDTextField/MDTextField";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { setBillToParcelAccountNumber, setBillToShippingCompany } from "../../_reducers_/FileCardSlice";

interface PropType {
  errors: any;
  control: any;
  setValue: any;
  shippingCompanies: DropDownItem[] | null;
}

const ShippingCompany = (props: PropType): ReactElement<PropType> => {
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);

  const { errors, control, setValue, shippingCompanies } = props;

  const [t] = useTranslation("general");
  const dispatch = useDispatch();

  return (
    <>
      {" "}
      <MDDropDown
        name="billToShippingCompanies"
        error={errors.billToShippingCompanies}
        control={control}
        label={t("billToAddress.shippingCompany")}
        onChangeSetValue={(event: string): void => {
          dispatch(setBillToShippingCompany(event));
        }}
        value={fileCard.fileCard.billToAddress.shippingCompany ?? ""}
        items={shippingCompanies !== null ? shippingCompanies : []}
        validationText={t("validation.requiredField")}
        defaultMenuItem={false}
        defaultMenuItemKey=""
        defaultMenuItemValue=""
      />
      {fileCard.fileCard.billToAddress.shippingCompany && fileCard.fileCard.billToAddress.shippingCompany > 0 ? (
        <MDTextField
          control={control}
          setValue={setValue}
          label={t("billToAddress.parcelAccountNumber")}
          name="billToParcelAccountNumber"
          error={errors.billToParcelAccountNumber}
          value={fileCard.fileCard.billToAddress.parcelAccountNumber ?? ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setBillToParcelAccountNumber(e));
          }}
          rules={{
            required: { message: t("validation.requiredField"), value: true },
            maxLength: {
              value: 50,
              message: t("validation.maxNumberDigits", { number: 50 }),
            },
          }}
        />
      ) : undefined}
    </>
  );
};

export default ShippingCompany;
