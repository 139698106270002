import { Button } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import { AxiosResponse } from "axios";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDDropDown, { DropDownItem } from "../../UI/MDDropDown/MDDropDown";
import { ALL_COMPANIES } from "../../_constants_/constants";
import { basicDataService } from "../../_services_/basicData.service";
import { CreateNewFileCardAction } from "../../_types_/actions/CreateNewFileCardAction";
import { CompanyType } from "../../_types_/CompanyType";
import { setCompanyCode, setIsCompanySelected } from "../../_reducers_/CreateNewFileCardSlice";

interface PropType {
  control: any;
  errors: any;
}

const ChooseCompany = (props: PropType): ReactElement<PropType> => {
  const dispatch = useDispatch();
  const createNewFileCard = useSelector(
    (state: { createNewFileCard: CreateNewFileCardAction }) => state.createNewFileCard
  );

  const [loading, setLoading] = useState(true);

  const [companyDropDownElements, setCompanyDropDownElements] = useState<DropDownItem[]>([
    { code: "0", value: "Error" },
  ]);

  useEffect(() => {
    const addCompanyToDropDownIfItIsInServerList = (
      companiesFromServer: CompanyType[],
      companyFromConstants: CompanyType,
      tempCompanyArray: DropDownItem[]
    ) => {
      if (companiesFromServer.findIndex((c) => c.code === companyFromConstants.code) !== -1) {
        tempCompanyArray.push({
          code: String(companyFromConstants.code),
          value: companyFromConstants.name,
        });
      }
    };

    const refreshCompanyDropDownWithBackendData = () => {
      setLoading(true);
      basicDataService
        .getCompaniesForUser()
        .then((response: AxiosResponse<CompanyType[]>): void => {
          const companiesFromServer: CompanyType[] = response.data;
          let tempCompanyArray: DropDownItem[] = [];
          tempCompanyArray.push({
            code: "0",
            value: "Please select a company",
          });
          ALL_COMPANIES.forEach((company) => {
            addCompanyToDropDownIfItIsInServerList(companiesFromServer, company, tempCompanyArray);
          });
          setCompanyDropDownElements(tempCompanyArray);
        })
        .finally((): void => {
          setLoading(false);
        });
    };

    refreshCompanyDropDownWithBackendData();
  }, []);

  return loading ? (
    <LinearProgress />
  ) : (
    <div>
      <h1>Create new file card</h1>
      <MDDropDown
        label="Company"
        value={String(createNewFileCard.NewFileCard.CompanyCode)}
        control={props.control}
        error={props.errors.company}
        name="company"
        validationText="Please choose a company"
        items={companyDropDownElements}
        onChangeSetValue={(event: string): void => {
          dispatch(setCompanyCode(Number(event)));
        }}
      />

      <Button
        variant="contained"
        color="primary"
        type="button"
        className="sendButton"
        onClick={(): void => {
          if (createNewFileCard.NewFileCard.CompanyCode !== 0) {
            dispatch(setIsCompanySelected(true));
          }
        }}
      >
        Next
      </Button>
    </div>
  );
};

export default ChooseCompany;
