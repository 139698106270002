import { configureStore } from "@reduxjs/toolkit";
import CreateNewFileCardSlice from "../_reducers_/CreateNewFileCardSlice";
import FileCardSlice from "../_reducers_/FileCardSlice";
import SigningSlice from "../_reducers_/SigningSlice";

const Store = configureStore({
  reducer: {
    createNewFileCard: CreateNewFileCardSlice,
    fileCard: FileCardSlice,
    signing: SigningSlice,
  },
});

export default Store;
