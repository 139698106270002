import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { BrandType } from "../../_types_/BrandType";
import Brand from "./Brand/Brand";
import classes from "./BrandsTable.module.scss";
interface PropType {
  brands: BrandType[] | undefined;
  style?: React.CSSProperties | undefined;
}

const BrandsTable = (props: PropType): ReactElement<PropType> => {
  const { t } = useTranslation("general");
  return (
    <div>
      <table className={classes.brandsTable} style={props.style}>
        <thead>
          <tr>
            <td className={classes.brandProductlineHeader}>{t("brandsTable.productLinesBrandsHeadline")}</td>
            <td className={classes.salesPersonHeader}>{t("brandsTable.salesPersonHeadline")}</td>
          </tr>
        </thead>
      </table>
      {props.brands
        ? props.brands.map((brand) => (
            <Brand key={brand.code} brandName={brand.name} productLines={brand.productLines} />
          ))
        : undefined}
      <div className={classes.tableBottomBorder} />
    </div>
  );
};

export default BrandsTable;
