import React, { ReactElement } from "react";
import { useSelector } from "react-redux";
import { properties } from "../../properties";
import { ALL_COMPANIES } from "../../_constants_/constants";
import { CreateNewFileCardAction } from "../../_types_/actions/CreateNewFileCardAction";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import logo_BESTSELLER from "./BESTSELLER_black.png";
import EnvironmentBox from "./EnvironmentBox/EnvironmentBox";
import classes from "./TopBar.module.scss";
import logo_VILA from "./VILA_black.png";

const TopBar = (): ReactElement => {
  const createNewFileCard = useSelector(
    (state: { createNewFileCard: CreateNewFileCardAction }) =>
      state.createNewFileCard
  );

  const fileCard = useSelector(
    (state: { fileCard: FileCardAction }) => state.fileCard
  );

  const environmentBox = properties.serviceApiURL?.includes(
    "https://bestcard.bestseller.com"
  ) ? null : (
    <>
      <EnvironmentBox />
    </>
  );

  const noCompany =
    createNewFileCard.NewFileCard.CompanyCode === 0 &&
    fileCard.fileCard.companyCode === 0 &&
    window.location.pathname !== "/";

  const company =
    createNewFileCard.NewFileCard.CompanyCode !== 0
      ? ALL_COMPANIES.find(
          (c) => c.code === createNewFileCard.NewFileCard.CompanyCode
        )
      : fileCard.fileCard.companyCode !== 0
      ? ALL_COMPANIES.find((c) => c.code === fileCard.fileCard.companyCode)
      : { code: 0, name: "" };

  return (
    <header className={classes.topBar}>
      {noCompany ? undefined : (
        <img
          src={isVila(company) ? logo_VILA : logo_BESTSELLER}
          onClick={() => {
            window.location.reload();
          }}
          className={isVila(company) ? classes.imgVila : classes.imgBestseller}
          alt="logo"
        />
      )}

      {environmentBox}
    </header>
  );
};

export default TopBar;
function isVila(company: { code: number; name: string } | undefined) {
  return (
    (company?.name.toLowerCase().indexOf("vila") !== undefined
      ? company?.name.toLowerCase().indexOf("vila")
      : -1) > -1
  );
}
