import React, { Dispatch, ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDDropDown, { DropDownItem } from "../../UI/MDDropDown/MDDropDown";
import MDTextField from "../../UI/MDTextField/MDTextField";
import { isAdminUrl } from "../../utils/QueryStringUtil";
import { ALL_COMPANIES, ONLY_NUMBERS_VALIDATION_REGEX } from "../../_constants_/constants";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import GuaranteeDescription from "./GuaranteeDescription";
import { setGuaranteeAmount, setGuaranteeType } from "../../_reducers_/FileCardSlice";

interface PropType {
  companyCode: number;
  errors: any;
  control: any;
  setValue: any;
  guaranteeAmountEnabled: boolean;
  guaranteeTypeEnabled: boolean;
}

const Guarantee = (props: PropType): ReactElement<PropType> => {
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const dispatch = useDispatch();
  const { t } = useTranslation("general");
  const [t_cc] = useTranslation(props.companyCode.toString());
  const [guaranteeAmountReadOnly, setGuaranteeAmountReadOnly] = useState(false);

  useEffect(() => {
    if (props.guaranteeTypeEnabled && !isAdminUrl()) {
      setGuaranteeTypeForFileCard(dispatch, 0);
    }
    const company = ALL_COMPANIES.find((c) => c.code === props.companyCode);
    if (company) {
      if (company.guaranteeAmountReadOnlyForCustomer && !isAdminUrl()) {
        setGuaranteeAmountReadOnly(true);
      }
    }
  }, [dispatch, props.guaranteeTypeEnabled, props.companyCode]);

  function getGuaranteeItems(): DropDownItem[] {
    var urlParams = new URLSearchParams(window.location.search);
    var rating = urlParams.get("rating");

    if (rating === "bad" || rating === "no") {
      return [
        { code: "1", value: t("guarantee.guaranteeType.option1") },
        { code: "2", value: t("guarantee.guaranteeType.option2") },
      ];
    } else {
      return [
        { code: "0", value: t("guarantee.guaranteeType.option0") },
        { code: "1", value: t("guarantee.guaranteeType.option1") },
        { code: "2", value: t("guarantee.guaranteeType.option2") },
      ];
    }
  }

  return (
    <div>
      <h2>{t("guarantee.headline")}</h2>
      {isAdminUrl() ? undefined : <GuaranteeDescription companyCode={props.companyCode} />}
      {props.guaranteeTypeEnabled ? (
        <MDDropDown
          name="guaranteeType"
          error={props.errors.guaranteeType}
          control={props.control}
          label={t("guarantee.guaranteeType.label")}
          onChangeSetValue={(event: string): void => {
            setGuaranteeTypeForFileCard(dispatch, Number(event));
          }}
          value={fileCard.fileCard.guaranteeType ? String(fileCard.fileCard.guaranteeType) : "0"}
          items={getGuaranteeItems()}
          validationText={t("validation.requiredField")}
          defaultMenuItem={true}
          defaultMenuItemKey=""
          defaultMenuItemValue=""
        />
      ) : undefined}
      {props.guaranteeAmountEnabled ? (
        <>
          {t_cc("html.extra-guarantee-amount-text") !== "html.extra-guarantee-amount-text" ? (
            <p>{t_cc("html.extra-guarantee-amount-text")}</p>
          ) : undefined}
          <MDTextField
            control={props.control}
            setValue={props.setValue}
            label={t("guarantee.guaranteeAmount")}
            name="guaranteeAmount"
            error={props.errors.guaranteeAmount}
            disabled={guaranteeAmountReadOnly}
            rules={{
              required: {
                message: t("validation.requiredField"),
                value: guaranteeAmountReadOnly ? false : true,
              },
              min: {
                message: t("guarantee.validation.minGuaranteeAmount", {
                  number: 0,
                }),
                value: 0,
              },
              max: {
                message: t("guarantee.validation.maxGuaranteeAmount", {
                  number: 2147483647,
                }),
                value: 2147483647,
              },
              pattern: {
                value: ONLY_NUMBERS_VALIDATION_REGEX,
                message: t("guarantee.validation.canOnlyHoldNumbers"),
              },
            }}
            value={fileCard.fileCard.guaranteeAmount != null ? String(fileCard.fileCard.guaranteeAmount) : ""}
            onChangeSetValue={(e: string): void => {
              dispatch(setGuaranteeAmount(e));
            }}
          />
        </>
      ) : undefined}
    </div>
  );
};

export default Guarantee;

function setGuaranteeTypeForFileCard(dispatch: Dispatch<any>, guaranteeTypeCode: number) {
  dispatch(setGuaranteeType(guaranteeTypeCode));
}
