import React, { Dispatch, ReactElement, useEffect, useState } from "react";
import { FieldValues } from "react-hook-form";
import { UseFormSetValue } from "react-hook-form/dist/types/form";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDDropDown, { DropDownItem } from "../../UI/MDDropDown/MDDropDown";
import { isAdminUrl } from "../../utils/QueryStringUtil";
import {
  ALL_COMPANIES,
  paymentTermHasDirectDebit,
  PAYMENT_TERM_CASH_BEFORE_DELIVERY,
} from "../../_constants_/constants";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { PaymentTermType } from "../../_types_/PaymentTermType";
import NetsForm from "./NetsForm/NetsForm";
import PaymentTermDescription from "./PaymentTermDescription";
import { setPaymentTermCode } from "../../_reducers_/FileCardSlice";

interface PropType {
  errors: any;
  control: any;
  trigger: (name?: string | string[] | undefined) => Promise<boolean>;
  setFormValue: UseFormSetValue<FieldValues>;
  formState: any;
  setGuaranteeVisible: any;
}

const PaymentTerm = (props: PropType): ReactElement<PropType> => {
  const dispatch = useDispatch();
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const [t] = useTranslation("general");
  const [showDropDown, setShowDropDown] = useState(false);
  const [noPaymentTerms, setNoPaymentTerms] = useState(false);
  const [onlyOnePaymentTerm, setOnlyOnePaymentTerm] = useState(false);
  const [paymentTermVisible, setPaymentTermVisible] = useState(true);
  const [showDirectDebitEvenIfPaymentTermNotVisible, setShowDirectDebitEvenIfPaymentTermNotVisible] = useState(false);

  const { control, errors, trigger, setFormValue, formState, setGuaranteeVisible } = props;

  const companyCode = fileCard.fileCard.companyCode;
  const paymentTermCode = fileCard.fileCard.paymentTermCode;

  useEffect(() => {
    const paymentTermsForCompanyCode = getPaymentTermsForCompanyCode(
      companyCode,
      fileCard.fileCard.documentLanguageCode,
      fileCard.fileCard.billToAddress?.countryISOAlpha3
    );

    if (paymentTermsForCompanyCode) {
      if (paymentTermsForCompanyCode.length > 1) {
        setShowDropDown(true);
      } else if (paymentTermsForCompanyCode.length === 1) {
        setPaymentTermForFileCard(dispatch, paymentTermsForCompanyCode[0].code);
        setFormValue("paymentTerm", paymentTermsForCompanyCode[0].code);
        trigger("paymentTerm");
        setOnlyOnePaymentTerm(true);
      }
      if (paymentTermsForCompanyCode.length === 0) {
        setNoPaymentTerms(true);
      }
      if (paymentTermCode) {
        if (paymentTermsForCompanyCode.findIndex((pt) => pt.code === paymentTermCode) === -1) {
          setPaymentTermForFileCard(dispatch, null);
          setFormValue("paymentTerm", "");
        }
      }
    }
  }, [
    dispatch,
    trigger,
    setFormValue,
    companyCode,
    fileCard.fileCard.documentLanguageCode,
    fileCard.fileCard.billToAddress?.countryISOAlpha3,
    paymentTermCode,
  ]);

  useEffect(() => {
    const company = ALL_COMPANIES.find((c) => c.code === companyCode);
    if (!isAdminUrl() && company?.paymentTermsNotVisibleForCustomers) {
      setPaymentTermVisible(false);
    } else {
      setPaymentTermVisible(true);
    }

    setShowDirectDebitEvenIfPaymentTermNotVisible(company?.showDirectDebitEvenIfPaymentTermNotVisible ?? false);
  }, [companyCode]);

  const checkDirectDebit = () => {
    return paymentTermHasDirectDebit(companyCode, paymentTermCode);
  };

  return (
    <div>
      {noPaymentTerms ? undefined : paymentTermVisible || showDirectDebitEvenIfPaymentTermNotVisible ? (
        <h2>{t("paymentTerm.headline")}</h2>
      ) : undefined}
      {(isAdminUrl() || noPaymentTerms) && !onlyOnePaymentTerm ? undefined : paymentTermVisible ||
        showDirectDebitEvenIfPaymentTermNotVisible ? (
        <PaymentTermDescription companyCode={companyCode} />
      ) : undefined}

      {showDropDown && paymentTermVisible ? (
        <MDDropDown
          name="paymentTerm"
          error={errors.paymentTerm}
          control={control}
          label={t("paymentTerm.dropdownLabel")}
          onChangeSetValue={(event: string): void => {
            setPaymentTermForFileCard(dispatch, event);

            if (event === PAYMENT_TERM_CASH_BEFORE_DELIVERY) {
              setGuaranteeVisible(false);
            } else {
              setGuaranteeVisible(true);
            }
          }}
          value={paymentTermCode ? paymentTermCode : ""}
          items={getPaymentTermsForCompanyCode(
            companyCode,
            fileCard.fileCard.documentLanguageCode,
            fileCard.fileCard.billToAddress?.countryISOAlpha3
          )}
          validationText={t("paymentTerm.validation.paymentTermRequired")}
          defaultMenuItem={false}
          defaultMenuItemKey=""
          defaultMenuItemValue=""
        />
      ) : undefined}

      {((paymentTermCode && checkDirectDebit()) ||
        noPaymentTerms ||
        (showDirectDebitEvenIfPaymentTermNotVisible && !isAdminUrl())) &&
      (paymentTermVisible || (showDirectDebitEvenIfPaymentTermNotVisible && !isAdminUrl())) ? (
        <NetsForm
          control={control}
          errors={errors}
          noPaymentTerms={noPaymentTerms}
          setValue={setFormValue}
          trigger={trigger}
          formState={formState}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default PaymentTerm;
function setPaymentTermForFileCard(dispatch: Dispatch<any>, paymentTermCode: string | null) {
  dispatch(setPaymentTermCode(paymentTermCode));
}

const getPaymentTermsForCompanyCode = (
  companyCode: number,
  documentLanguageCode: string | null,
  countryIsoAlpha3: string | null
): DropDownItem[] => {
  const company = ALL_COMPANIES.find((c) => c.code === companyCode);

  const paymentTerms = company?.paymentTerms;
  const languageCode = documentLanguageCode !== null ? documentLanguageCode : company?.language;

  if (paymentTerms !== undefined) {
    const dropDownItems: DropDownItem[] = paymentTerms.flatMap((paymentTerm: PaymentTermType) => {
      const name = paymentTerm.name.find((n) => n.language.toLowerCase() === languageCode?.toLowerCase());
      if (paymentTerm.notVisibleForCountry) {
        if (paymentTerm.notVisibleForCountry === countryIsoAlpha3) {
          return [];
        } else {
          return {
            code: paymentTerm.code,
            value: name ? name.value : "Error",
            description: paymentTerm.description.find((d) => d.language.toLowerCase() === languageCode?.toLowerCase())
              ?.value,
          };
        }
      } else if (paymentTerm.onlyVisibleForCountry) {
        if (paymentTerm.onlyVisibleForCountry === countryIsoAlpha3) {
          return {
            code: paymentTerm.code,
            value: name ? name.value : "Error",
            description: paymentTerm.description.find((d) => d.language.toLowerCase() === languageCode?.toLowerCase())
              ?.value,
          };
        } else {
          return [];
        }
      } else {
        return {
          code: paymentTerm.code,
          value: name ? name.value : "Error",
          description: paymentTerm.description.find((d) => d.language.toLowerCase() === languageCode?.toLowerCase())
            ?.value,
        };
      }
    });
    return dropDownItems;
  }

  return [{ code: "-1", value: "error", description: "error" }];
};
