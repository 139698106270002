import React, { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDTextField from "../../UI/MDTextField/MDTextField";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { setComments } from "../../_reducers_/FileCardSlice";

interface PropType {
  errors: any;
  control: any;
}

const Comments = (props: PropType): ReactElement => {
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const dispatch = useDispatch();
  const { t } = useTranslation("general");
  return (
    <div>
      <h2>{t("comment.headline")}</h2>
      <MDTextField
        label={t("comment.commentField")}
        name="comments"
        error={props.errors.comments}
        control={props.control}
        multiline={true}
        rules={{
          required: {
            message: t("validation.requiredField"),
            value: false,
          },
        }}
        value={fileCard.fileCard.comments != null ? String(fileCard.fileCard.comments) : ""}
        onChangeSetValue={(e: string): void => {
          dispatch(setComments(e));
        }}
      />
    </div>
  );
};

export default Comments;
