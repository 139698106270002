import axios from "axios";
import React, { ReactElement, useState } from "react";

const ResendSigning = (): ReactElement => {
  const [customerIdentifier, setCustomerIdentifier] = useState<string>("");

  const [environment, setEnvironment] = useState(
    "https://bestcardtest.bestseller.com"
  );

  const ResendSigning = () => {
    axios({
      url:
        environment +
        "/hooks/mda/customers/" +
        customerIdentifier +
        "/approved",
      method: "put",
      withCredentials: true,
    }).then((res) => {
      console.log("data", res.data);
    });
  };

  return (
    <div>
      <h1>Resend signing</h1>
      <select
        onChange={(event) => {
          setEnvironment(event.target.value);
        }}
        defaultValue="https://bestcardtest.bestseller.com"
      >
        <option value="https://bestcardtest.bestseller.com">TEST</option>
        <option value="https://bestcard.bestseller.com">PROD</option>
        <option value="https://localhost:44325">DEV (localhost)</option>
      </select>
      <input
        type="input"
        onChange={(event) => {
          setCustomerIdentifier(event.target.value);
        }}
      />

      <button onClick={() => ResendSigning()}>Resend</button>
    </div>
  );
};

export default ResendSigning;
