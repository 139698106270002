import React, { ChangeEvent, ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDRadioButton from "../../UI/MDRadioButton/MDRadioButton";
import MDTextField from "../../UI/MDTextField/MDTextField";
import { ONLY_NUMBERS_VALIDATION_REGEX } from "../../_constants_/constants";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import TemporarySalesTaxVendor from "./TemporarySalesTaxVendor";
import {
  setBillToCertificateOfAuthorityNumber,
  setBillToRegisteredAsSalesTaxVendor,
  setBillToSalesTaxVendorExpiration,
} from "../../_reducers_/FileCardSlice";

interface PropType {
  errors: any;
  control: any;
  setValue: any;
}

const SalesTaxVendor = (props: PropType): ReactElement<PropType> => {
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const dispatch = useDispatch();
  const [t] = useTranslation("general");

  return (
    <>
      <MDRadioButton
        control={props.control}
        error={props.errors.salesTaxVendor}
        name="salesTaxVendor"
        label={t("billToAddress.salesTaxVendor.label")}
        onChangeSetValue={(event: ChangeEvent<HTMLInputElement>): void => {
          dispatch(setBillToRegisteredAsSalesTaxVendor((event.target as HTMLInputElement).value === "true"));
          dispatch(setBillToSalesTaxVendorExpiration(null));
        }}
        valueToMatchCheckedWith={
          fileCard.fileCard.billToAddress.registeredAsSalesTaxVendor === undefined
            ? null
            : fileCard.fileCard.billToAddress.registeredAsSalesTaxVendor
        }
        opt1Label={t("shared.yes")}
        opt2Label={t("shared.no")}
        setValue={props.setValue}
        opt1Value={true}
        opt2Value={false}
        validationText={t("validation.selectionRequired")}
      />
      {fileCard.fileCard.billToAddress.registeredAsSalesTaxVendor ? (
        <>
          <MDTextField
            control={props.control}
            setValue={props.setValue}
            label={t("billToAddress.salesTaxVendor.certificateOfAuthorityNumber")}
            name="certificateOfAuthorityNumber"
            error={props.errors.certificateOfAuthorityNumber}
            value={fileCard.fileCard.billToAddress.certificateOfAuthorityNumber ?? ""}
            onChangeSetValue={(e: string): void => {
              dispatch(setBillToCertificateOfAuthorityNumber(e));
            }}
            rules={{
              required: {
                message: t("validation.requiredField"),
                value: true,
              },
              minLength: {
                message: t("validation.minNumberDigits", { number: 9 }),
                value: 9,
              },
              maxLength: {
                message: t("validation.maxNumberDigits", { number: 11 }),
                value: 11,
              },
              pattern: {
                value: ONLY_NUMBERS_VALIDATION_REGEX,
                message: t("validation.canOnlyHoldNumbers"),
              },
            }}
          />
          <TemporarySalesTaxVendor control={props.control} errors={props.errors} />
        </>
      ) : undefined}
    </>
  );
};

export default SalesTaxVendor;
