import { Autocomplete, TextField } from "@mui/material";
import { ReactElement, useEffect } from "react";
import { Controller } from "react-hook-form";
import { isNullOrUndefined } from "../../utils/CommonUtils";

interface PropType {
  control: any;
  multiple: any;
  label: string;
  value?: any;
  error: any;
  name: string;
  items: any;
  onChangeSetValue: (value: any) => void;
  validationText?: string;
  setValue?: any;
  getOptionLabel: any;
}

const MDAutoComplete = (props: PropType): ReactElement<PropType> => {
  const { multiple, name, value, setValue, validationText, getOptionLabel } = props;

  useEffect(() => {
    if (setValue !== undefined) {
      setValue(name, value);
    }
  }, [name, value, setValue]);

  return (
    <>
      <Controller
        control={props.control}
        render={({ field }) => (
          <>
            <Autocomplete
              multiple={multiple}
              value={value || null}
              options={props.items}
              getOptionLabel={getOptionLabel}
              onChange={(event, newValue) => {
                props.onChangeSetValue(newValue);
                field.onChange(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputRef={field.ref}
                  fullWidth
                  variant="outlined"
                  label={props.label}
                  error={props.error ? true : false}
                  helperText={props.error && props.error.message}
                />
              )}
              renderOption={(props, option, { selected }) => {
                return (
                  <>
                    <li {...props} key={option.id}>
                      {getOptionLabel(option)}
                    </li>
                  </>
                );
              }}
            />
          </>
        )}
        name={name}
        rules={
          validationText
            ? {
                validate: (value: any[]) => {
                  return !isNullOrUndefined(value) ? true : validationText;
                },
              }
            : undefined
        }
        defaultValue={value}
      />
    </>
  );
};

export default MDAutoComplete;
