const InternetExplorerNotAllowed = () => {
  return (
    <div>
      This site does not support Internet Explorer. <br />
      <br /> Please use another browser to access this site.
    </div>
  );
};

export default InternetExplorerNotAllowed;
