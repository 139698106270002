import { InputAdornment, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { Controller, FieldValues, RegisterOptions } from "react-hook-form";
import { usePaperStyles } from "../../_styling_/usePaperStyles";

interface PropTypes {
  name: string;
  label: string;
  value: any;
  error: any;
  rules?:
    | Omit<RegisterOptions<FieldValues, string>, "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs">
    | undefined;
  control: any;
  disabled?: boolean;
  onChangeSetValue: (value: any) => void;
  className?: string;
  flex?: string;
  prefixText?: string;
  multiline?: boolean;
  rows?: number;
  type?: string;
  inputProps?: any;
  InputLabelProps?: any;
  setValue?: any;
  trigger?: any;
  formState?: any;
}

const MDTextField: React.FC<PropTypes> = (props: PropTypes) => {
  const classes = usePaperStyles();

  const { name, value, setValue } = props;

  const val = value !== null && value !== undefined ? value : "";

  useEffect(() => {
    if (setValue !== undefined) {
      setValue(name, value);
    }
  }, [name, value, setValue]);

  return (
    <>
      <Controller
        control={props.control}
        render={({ field }) => (
          <TextField
            multiline={props.multiline}
            rows={props.rows}
            label={props.label}
            inputRef={field.ref}
            inputProps={props.inputProps}
            InputLabelProps={props.InputLabelProps}
            type={props.type}
            variant="outlined"
            helperText={props.error && props.error.message}
            error={props.error ? true : false}
            onChange={(event) => {
              props.onChangeSetValue(event.target.value);
              field.onChange(event.target.value);
            }}
            InputProps={
              props.prefixText
                ? {
                    startAdornment: (
                      <InputAdornment position="start" style={{ marginRight: "-3px" }}>
                        {props.prefixText}
                      </InputAdornment>
                    ),
                  }
                : undefined
            }
            className={props.className ? props.className : classes.TextValidator}
            value={val}
            disabled={props.disabled ?? false}
          />
        )}
        name={name}
        rules={props.rules}
        defaultValue={val}
      />
    </>
  );
};

export default MDTextField;
