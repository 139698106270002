import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDSwitchButton from "../../UI/MDSwitchButton/MDSwitchButton";
import { removeTimeFromDate } from "../../utils/DateUtils";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { isNullOrUndefined } from "../../utils/CommonUtils";
import MDDate from "../../UI/MDDate/MDDate";
import { setBillToSalesTaxVendorExpiration } from "../../_reducers_/FileCardSlice";

interface PropType {
  control: any;
  errors: any;
}

const TemporarySalesTaxVendor = (props: PropType): ReactElement<PropType> => {
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const dispatch = useDispatch();
  const [t] = useTranslation("general");
  const [switchChecked, setSwitchChecked] = useState(false);
  const switchLabel = t("billToAddress.salesTaxVendor.temporarySalesTaxVendor");
  const dateLabel = t("billToAddress.salesTaxVendor.expirationDate");

  useEffect(() => {
    if (switchChecked === false && fileCard.fileCard.billToAddress.salesTaxVendorExpiration !== null) {
      setSwitchChecked(true);
    }
  }, [switchChecked, fileCard.fileCard.billToAddress.salesTaxVendorExpiration]);

  const validateDate = (calendarDate: string) => {
    const todayWithTime = new Date();
    const todayNoTime = removeTimeFromDate(todayWithTime);
    const calendarDateNoTime = removeTimeFromDate(new Date(calendarDate));

    if (calendarDateNoTime?.getTime() !== undefined && todayNoTime?.getTime() !== undefined) {
      if (calendarDateNoTime?.getTime() >= todayNoTime?.getTime()) {
        return true;
      } else {
        return t("validation.earliestDateIsToday");
      }
    } else {
      return t("validation.earliestDateIsToday");
    }
  };

  const { control, errors } = props;
  return (
    <>
      {" "}
      <MDSwitchButton
        control={control}
        label={switchLabel}
        name="switchChecked"
        error={errors.switchChecked}
        value={switchChecked}
        onChangeSetValue={(checked: boolean) => {
          setSwitchChecked(checked);
          if (!checked) {
            dispatch(setBillToSalesTaxVendorExpiration(null));
          }
        }}
      />
      {switchChecked ? (
        <MDDate
          control={control}
          disabled={false}
          dateLabel={dateLabel}
          dateName="salesTaxVendorExpiration"
          dateError={props.errors.salesTaxVendorExpiration}
          dateFormat="MM-dd-yyyy"
          dateValue={
            isNullOrUndefined(fileCard.fileCard.billToAddress.salesTaxVendorExpiration)
              ? null
              : fileCard.fileCard.billToAddress.salesTaxVendorExpiration
          }
          dateOnChange={(e: string): void => {
            dispatch(setBillToSalesTaxVendorExpiration(e));
          }}
          rules={{
            required: {
              message: t("validation.requiredField"),
              value: true,
            },
            validate: validateDate,
          }}
        />
      ) : undefined}
    </>
  );
};

export default TemporarySalesTaxVendor;
