import parsePhoneNumber from "libphonenumber-js/max";
import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CALLING_CODE_VALIDATION_REGEX, ONLY_NUMBERS_VALIDATION_REGEX } from "../../_constants_/constants";
import postalCodeCityClasses from "../MDPostalCodeCityField/MDPostalCodeCityField.module.scss";
import MDTextField from "../MDTextField/MDTextField";

interface PropType {
  control: any;
  trigger: any;
  formState: any;
  setValue: any;
  callingCodeError: any;
  callingCodeName: string;
  callingCodeValue: string;
  callingCodeOnChange: (value: any) => void;
  phoneNumberError: any;
  phoneNumberValue: string;
  phoneNumberOnChange: (value: any) => void;
  phoneNumberLabel: string;
  phoneNumberName: string;
  phoneNumberRequired: boolean;
  disabled?: boolean;
}

const MDPhoneNumberField = (props: PropType): ReactElement<PropType> => {
  const { t } = useTranslation("general");

  const validatePhoneNumber = (value: string) => {
    if (value === null || value === "") {
      return true;
    }
    const phoneNumber = parsePhoneNumber(props.callingCodeValue + value);
    return phoneNumber?.isValid() ? true : t("validation.wrongFormat");
  };

  const onChangeCallingCode = (value: string) => {
    props.callingCodeOnChange(value);
  };

  const { trigger, callingCodeValue, formState, phoneNumberName, phoneNumberValue, callingCodeName, setValue } = props;

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger(phoneNumberName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingCodeValue, phoneNumberValue]);

  useEffect(() => {
    setValue(callingCodeName, callingCodeValue);
    if (formState.isSubmitted) {
      trigger(callingCodeName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callingCodeValue]);

  return (
    <div className={postalCodeCityClasses.flexContainer}>
      <MDTextField
        className={postalCodeCityClasses.callingCode}
        control={props.control}
        setValue={props.setValue}
        label={t("shared.calling-code")}
        name={props.callingCodeName}
        error={props.callingCodeError}
        rules={{
          required: {
            message: t("validation.requiredField"),
            value: true,
          },
          pattern: {
            value: CALLING_CODE_VALIDATION_REGEX,
            message: t("validation.wrongFormat"),
          },
        }}
        value={props.callingCodeValue}
        onChangeSetValue={onChangeCallingCode}
        disabled={props.disabled ? props.disabled : false}
      />
      <MDTextField
        className={postalCodeCityClasses.phoneNumber}
        control={props.control}
        setValue={props.setValue}
        label={props.phoneNumberLabel}
        name={props.phoneNumberName}
        error={props.phoneNumberError}
        rules={{
          required: {
            message: t("validation.requiredField"),
            value: props.phoneNumberRequired,
          },
          pattern: {
            value: ONLY_NUMBERS_VALIDATION_REGEX,
            message: t("validation.canOnlyHoldNumbers"),
          },
          validate: validatePhoneNumber,
        }}
        value={props.phoneNumberValue}
        onChangeSetValue={props.phoneNumberOnChange}
        disabled={props.disabled ? props.disabled : false}
      />
    </div>
  );
};

export default MDPhoneNumberField;
