import { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MDTextField from "../../../UI/MDTextField/MDTextField";
import { ALL_COMPANIES, ONLY_NUMBERS_VALIDATION_REGEX } from "../../../_constants_/constants";
import { usePaperStyles } from "../../../_styling_/usePaperStyles";
import { FileCardAction } from "../../../_types_/actions/FileCardAction";

import {
  setBillingAccountNumber,
  setBillingBankName,
  setBillingBic,
  setBillingIbanNumber,
  setBillingLegalName,
  setBillingRegNumber,
  setBillingStoreName,
} from "../../../_reducers_/FileCardSlice";

interface PropType {
  errors: any;
  control: any;
  noPaymentTerms: boolean;
  trigger: any;
  formState: any;
  setValue: any;
}
const NetsForm = (props: PropType): ReactElement<PropType> => {
  const dispatch = useDispatch();
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const classes = usePaperStyles();

  const { t } = useTranslation("general");

  const [regNumberEnabled, setRegNumberEnabled] = useState(false);
  const [accountNumberEnabled, setAccountNumberEnabled] = useState(false);
  const [billingEnabled, setBillingEnabled] = useState(true);
  const [bankNameEnabled, setBankNameEnabled] = useState(true);
  const [BICEnabled, setBICEnabled] = useState(false);
  const [regNumberMinDigits, setRegNumberMinDigits] = useState(4);
  const [regNumberMaxDigits, setRegNumberMaxDigits] = useState(4);

  const [ibanRegEx, setIbanRegEx] = useState<string | null>();
  const [ibanFormat, setIbanFormat] = useState<string | null>();

  const [bicRequired, setBicRequired] = useState(true);
  const [ibanRequired, setIbanRequired] = useState(true);
  const [legalNameRequired, setLegalNameRequired] = useState(true);
  const [storeNameRequired, setStoreNameRequired] = useState(true);

  const { errors, control, trigger, setValue, formState } = props;

  const billing = fileCard.fileCard.billing;

  useEffect(() => {
    const company = ALL_COMPANIES.find((c) => c.code === fileCard.fileCard.companyCode);

    if (company) {
      setRegNumberEnabled(company.regNumber);
      setAccountNumberEnabled(company.accountNumber);
      setBillingEnabled(company.billing);
      setBankNameEnabled(company.bankName);
      setBICEnabled(company.BIC);
      setRegNumberMaxDigits(company.regNumberMaxDigits ?? 4);
      setRegNumberMinDigits(company.regNumberMinDigits ?? 4);
    }
  }, [fileCard.fileCard.companyCode]);

  useEffect(() => {
    if (fileCard.fileCard.contractType === 1) {
      //required if contract type is direct debit
      setBicRequired(true);
      setIbanRequired(true);
      setLegalNameRequired(true);
      setStoreNameRequired(true);
    } else {
      const company = ALL_COMPANIES.find((c) => c.code === fileCard.fileCard.companyCode);
      if (company) {
        if (company.directDebitBICOptional) {
          setBicRequired(false);
        }
        if (company.directDebitIBANOptional) {
          setIbanRequired(false);
        }
        if (company.directDebitLegalNameOptional) {
          setLegalNameRequired(false);
        }
        if (company.directDebitStoreNameOptional) {
          setStoreNameRequired(false);
        }
      }
    }
  }, [fileCard.fileCard.contractType, fileCard.fileCard.companyCode]);

  useEffect(() => {
    if (fileCard.allCountries !== null && fileCard.fileCard.billToAddress.countryISOAlpha3 !== null) {
      const country = fileCard.allCountries.find(
        (c) => c.isoAlpha3 === fileCard.fileCard.billToAddress.countryISOAlpha3
      );
      setIbanRegEx(country?.regExIbanPattern);
      setIbanFormat(country?.displayIbanPattern);
    }
  }, [fileCard.allCountries, fileCard.fileCard.billToAddress.countryISOAlpha3, dispatch]);

  useEffect(() => {
    if (formState.isSubmitted) {
      trigger("billingIbanNumber");
    }
  }, [ibanRegEx, trigger, formState.isSubmitted]);

  useEffect(() => {
    if (formState.isSubmitted) {
      setValue("billingIbanNumber", billing?.ibanNumber);
      trigger("billingIbanNumber");
    }
  }, [billing?.ibanNumber, trigger, formState.isSubmitted, setValue]);

  const validateIban = (value: string | null) => {
    if (value === null || ibanRegEx === null) {
      return true;
    } else {
      const re = new RegExp(ibanRegEx ?? "");
      return re.test(String(value).toUpperCase())
        ? true
        : t("validation.wrongFormatWithExample", {
            format: ibanFormat,
          });
    }
  };

  const validateBIC = (value: string | null) => {
    if (value === null || value === "") {
      return true;
    } else {
      const re = new RegExp("^([a-zA-Z]){4}([a-zA-Z]){2}([0-9a-zA-Z]){2}([0-9a-zA-Z]{3})?$");
      return re.test(value)
        ? true
        : t("validation.wrongFormatWithExample", {
            format: "AAAABBCC123",
          });
    }
  };

  return !billingEnabled ? (
    <div></div>
  ) : (
    <>
      {props.noPaymentTerms ? <h2>{t("directDebit.headline")}</h2> : <h3>{t("directDebit.headline")}</h3>}

      <MDTextField
        label={t("directDebit.legalName")}
        name="billingLegalName"
        error={errors.billingLegalName}
        rules={{
          required: {
            message: t("validation.requiredField"),
            value: legalNameRequired,
          },
        }}
        control={control}
        value={billing ? billing.legalName : ""}
        onChangeSetValue={(e: string): void => {
          dispatch(setBillingLegalName(e));
        }}
      />
      <MDTextField
        label={t("directDebit.storeName")}
        name="billingStoreName"
        error={errors.billingStoreName}
        control={control}
        rules={{
          required: {
            message: t("validation.requiredField"),
            value: storeNameRequired,
          },
        }}
        value={billing ? billing.storeName : ""}
        onChangeSetValue={(e: string): void => {
          dispatch(setBillingStoreName(e));
        }}
      />

      {bankNameEnabled ? (
        <MDTextField
          label={t("directDebit.bank")}
          name="billingBank"
          error={errors.billingBank}
          control={control}
          rules={{
            required: { message: t("validation.requiredField"), value: true },
          }}
          value={billing ? billing.bankName : ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setBillingBankName(e));
          }}
        />
      ) : undefined}

      {regNumberEnabled ? (
        <MDTextField
          label={t("directDebit.regNumber")}
          name="billingRegNumber"
          error={errors.billingRegNumber}
          control={control}
          rules={{
            required: { message: t("validation.requiredField"), value: true },
            min: {
              message: t("validation.cannotBeANegativeNumber"),
              value: 0,
            },
            maxLength: {
              message: t("validation.maxNumberDigits", {
                number: regNumberMaxDigits,
              }),
              value: regNumberMaxDigits,
            },
            minLength: {
              message: t("validation.minNumberDigits", {
                number: regNumberMinDigits,
              }),
              value: regNumberMinDigits,
            },
            pattern: {
              value: ONLY_NUMBERS_VALIDATION_REGEX,
              message: t("validation.canOnlyHoldNumbers"),
            },
          }}
          className={classes.PostalCode}
          value={billing ? billing.regNumber : ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setBillingRegNumber(e));
          }}
        />
      ) : undefined}

      {accountNumberEnabled ? (
        <MDTextField
          label={t("directDebit.accountNumber")}
          name="billingAccountNumber"
          error={errors.billingAccountNumber}
          control={control}
          rules={{
            required: { message: t("validation.requiredField"), value: true },
            min: {
              message: t("validation.cannotBeANegativeNumber"),
              value: 1,
            },
            pattern: {
              value: ONLY_NUMBERS_VALIDATION_REGEX,
              message: t("validation.canOnlyHoldNumbers"),
            },
          }}
          className={regNumberEnabled ? classes.City : undefined}
          value={billing ? billing.accountNumber : ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setBillingAccountNumber(e));
          }}
        />
      ) : undefined}

      <MDTextField
        label={t("directDebit.ibanNumber")}
        name="billingIbanNumber"
        error={errors.billingIbanNumber}
        control={control}
        rules={{
          required: {
            message: t("validation.requiredField"),
            value: ibanRequired,
          },
          validate: validateIban,
        }}
        value={billing ? billing.ibanNumber : ""}
        onChangeSetValue={(e: string): void => {
          dispatch(setBillingIbanNumber(e));
        }}
      />

      {BICEnabled ? (
        <MDTextField
          label={t("directDebit.BIC")}
          name="billingBIC"
          error={errors.billingBIC}
          control={control}
          rules={{
            required: {
              message: t("validation.requiredField"),
              value: bicRequired,
            },
            validate: validateBIC,
          }}
          value={billing ? billing.bic : ""}
          onChangeSetValue={(e: string): void => {
            dispatch(setBillingBic(e));
          }}
        />
      ) : undefined}
    </>
  );
};

export default NetsForm;
