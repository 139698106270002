export const getQueryStringValue = (key: string, queryString = window.location.search) => {
  // Parse the query string into an object
  const urlParams = new URLSearchParams(queryString);

  const result = urlParams.get(key);
  // Return the value of the specified parameter
  return result !== null ? result : undefined;
};

export const stringIsNotNullOrEmpty = (input: string | string[] | null): boolean => {
  return input !== null && input !== undefined && input !== "";
};

export const getTokenFromUrl = (): string => {
  const path = window.location.pathname;
  const parts = path.split("/");
  const lastPart = parts[parts.length - 1];
  return lastPart;
};

export const isAdminUrl = (): boolean => {
  if (window.location.pathname.toLowerCase().includes("/admin/")) {
    return true;
  }
  return false;
};

export const isFileCardListUrl = (): boolean => {
  if (window.location.pathname.toLowerCase().includes("/admin/list")) {
    return true;
  }
  return false;
};

export const isEditUrl = (): boolean => {
  if (window.location.pathname.toLowerCase().includes("/admin/edit")) {
    return true;
  }
  return false;
};
