import { ReactElement } from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller, ValidationValueMessage } from "react-hook-form";

interface PropTypes {
  className?: string;
  control: any;
  disabled?: boolean;
  minDate?: any;
  dateLabel: string;
  dateName: string;
  dateError: any;
  dateValue: any;
  dateFormat: string;
  dateOnChange: (value?: any) => void;
  rules?:
    | Partial<{
        required: string | boolean | ValidationValueMessage<boolean>;
        validate: any;
      }>
    | undefined;
}

const MDDate = (props: PropTypes): ReactElement<PropTypes> => {
  return (
    <>
      <Controller
        control={props.control}
        render={({ field }) => (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              views={["year", "month", "day"]}
              inputRef={field.ref}
              disabled={props.disabled}
              minDate={props.minDate}
              format={props.dateFormat}
              label={props.dateLabel}
              value={props.dateValue ? new Date(props.dateValue) : null}
              onChange={(event: Date | null) => {
                if (event !== null && isNaN(event.getTime()) === false) {
                  const offsetDate = new Date(event.getTime() - event.getTimezoneOffset() * 60000);
                  props.dateOnChange(offsetDate.toISOString());
                  field.onChange(offsetDate.toISOString());
                }
              }}
              slotProps={{
                textField: {
                  error: props.dateError,
                  helperText: props.dateError && props.dateError.message,
                  variant: "outlined",
                  fullWidth: true,
                },
              }}
            />
          </LocalizationProvider>
        )}
        name={props.dateName}
        defaultValue={props.dateValue ? new Date(props.dateValue) : ""}
        rules={props.rules}
      />
    </>
  );
};

export default MDDate;
