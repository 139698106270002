import SaveIcon from "@mui/icons-material/Save";
import { Button, Snackbar } from "@mui/material";
import SnackbarContent from "@mui/material/SnackbarContent";
import React, { ReactElement, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import MDDropDown from "../../UI/MDDropDown/MDDropDown";
import { isEditUrl } from "../../utils/QueryStringUtil";
import { ALL_COMPANIES } from "../../_constants_/constants";
import { fileCardService } from "../../_services_/fileCard.service";

import { SignerServerType } from "../../_types_/serverTypes/SignerServerType";

import { FileCardAction } from "../../_types_/actions/FileCardAction";
import { ContractType } from "../../_types_/ContractType";
import { AxiosResponse } from "axios";
import { setContractType, setSigner } from "../../_reducers_/FileCardSlice";

interface PropType {
  errors: any;
  control: any;
  setApprove: (value: boolean) => void;
  setSaved: (value: boolean) => void;
  saved: boolean;
}

const AdminControls = (props: PropType): ReactElement<PropType> => {
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);

  const [signers, setSigners] = useState<SignerServerType[]>([
    { employeeName: "Error", employeeNumber: 0, mobilePhoneNumber: null },
  ]);

  const dispatch = useDispatch();
  const [contractTypes, setContractTypes] = useState<ContractType[] | null>(null);
  const [showSignerDropdown, setShowSignerDropDown] = useState(false);

  useEffect(() => {
    const company = ALL_COMPANIES.find((c) => c.code === fileCard.fileCard.companyCode);

    if (company?.signerDropDown) {
      setShowSignerDropDown(true);
      fileCardService
        .getSigners(fileCard.fileCard.companyCode)
        .then((response: AxiosResponse<SignerServerType[]>): void => {
          const signersFromServer: SignerServerType[] = response.data;

          setSigners(signersFromServer);
        })
        .finally((): void => {});
    }
    setContractTypes(company?.contractTypes ?? null);
  }, [fileCard.fileCard.companyCode]);

  return (
    <div>
      {contractTypes ? (
        <>
          <h2>Contract Type</h2>
          <div style={{ marginBottom: "30px" }}>
            <MDDropDown
              name="contractTypes"
              error={props.errors.contractTypes}
              label="Selected contract type"
              items={contractTypes.map(function (s) {
                return {
                  code: String(s.code),
                  value: s.name,
                };
              })}
              control={props.control}
              value={fileCard.fileCard.contractType ?? ""}
              onChangeSetValue={(event: string): void => {
                dispatch(setContractType(Number(event)));
              }}
              validationText="Please select a contract type"
              defaultMenuItem={true}
              defaultMenuItemKey=""
              defaultMenuItemValue=""
            />
          </div>
        </>
      ) : undefined}
      {showSignerDropdown ? (
        <>
          <h2>Signer</h2>
          <div style={{ marginBottom: "30px" }}>
            <MDDropDown
              name="signerDropDown"
              error={props.errors.signerDropDown}
              label="Selected Signer"
              items={signers.map(function (s) {
                return {
                  code: String(s.employeeNumber),
                  value: s.employeeName,
                };
              })}
              control={props.control}
              value={fileCard.fileCard.signer?.employeeNumber ?? ""}
              onChangeSetValue={(event: string): void => {
                dispatch(
                  setSigner({
                    employeeNumber: Number(event),
                    phoneNumber: signers.find((s) => String(s.employeeNumber) === event)?.mobilePhoneNumber,
                  })
                );
              }}
              validationText="Please select a signer"
              defaultMenuItem={true}
              defaultMenuItemKey=""
              defaultMenuItemValue=""
            />
          </div>
        </>
      ) : undefined}
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          props.setApprove(false);
        }}
        type="submit"
      >
        Save
        <SaveIcon style={{ marginLeft: 10 }} />
      </Button>
      {isEditUrl() ? undefined : (
        <Button
          style={{ float: "right" }}
          variant="contained"
          color="primary"
          onClick={() => {
            props.setApprove(true);
          }}
          type="submit"
        >
          Approve and Save
          <SaveIcon style={{ marginLeft: 10 }} />
        </Button>
      )}

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={props.saved}
        autoHideDuration={5000}
        onClose={(): void => {
          props.setSaved(false);
        }}
      >
        <SnackbarContent message="The filecard has been saved!" />
      </Snackbar>
    </div>
  );
};

export default AdminControls;
