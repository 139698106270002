import React, { ReactElement } from "react";
import classes from "./PageNotAvailable.module.scss";

const PageNotAvailable = (): ReactElement => {
  return (
    <div>
      <p>
        Sorry we could not find the page you are trying to access. Please check if there is an typing error in the link
        you used. Alternatively you are welcome to contact the Debtor Administration for help.
        {/* Vi kan desværre ikke finde den side, du forsøger at tilgå. Kontrollér
      venligst, om der er sket en indtastningsfejl i det link, du har brugt.
      Alternativt er du velkommen til at kontakte Debitoradministrationen (
      dk.debitor@bestseller.com eller tlf. 9942 1695) for at få hjælp. */}
      </p>
      <table className={classes.mainTable}>
        <thead>
          <tr>
            <td className={classes.mainTd}>Country</td>
            <td className={classes.mainTd}>E-mail</td>
            <td className={classes.mainTd}>Phone number</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={classes.subTd}>Austria</td>
            <td className={classes.subTd}>finance-at@bestseller.com</td>
            <td className={classes.subTd}>+43 1 4407434 4900</td>
          </tr>
          <tr>
            <td className={classes.subTd}>Belgium</td>
            <td className={classes.subTd}>adebtors.belgium@bestseller.com</td>
            <td className={classes.subTd}>+32 3 237 50 00</td>
          </tr>
          <tr>
            <td className={classes.subTd}>Canada</td>
            <td className={classes.subTd}>customercare@bestseller.com</td>
            <td className={classes.subTd}></td>
          </tr>
          <tr>
            <td className={classes.subTd}>Denmark</td>
            <td className={classes.subTd}>dk.debitor@bestseller.com</td>
            <td className={classes.subTd}>+45 99 42 16 95</td>
          </tr>
          <tr>
            <td className={classes.subTd}>Finland</td>
            <td className={classes.subTd}>henna.sarkela@bestseller.com</td>
            <td className={classes.subTd}>+358 20 7474669</td>
          </tr>
          <tr>
            <td className={classes.subTd}>France</td>
            <td className={classes.subTd}>compta-clients@bestseller.com</td>
            <td className={classes.subTd}>+33 1 53 25 13 70</td>
          </tr>
          <tr>
            <td className={classes.subTd}>Germany</td>
            <td className={classes.subTd}>customergermany@bestseller.com</td>
            <td className={classes.subTd}>+49 40 53030</td>
          </tr>
          <tr>
            <td className={classes.subTd}>Italy</td>
            <td className={classes.subTd}></td>
            <td className={classes.subTd}>051/6955700</td>
          </tr>
          <tr>
            <td className={classes.subTd}>Netherlands</td>
            <td className={classes.subTd}>administratienl@bestseller.com</td>
            <td className={classes.subTd}>+31 20 4454400</td>
          </tr>
          <tr>
            <td className={classes.subTd}>Norway</td>
            <td className={classes.subTd}>debitornorway@bestseller.com</td>
            <td className={classes.subTd}>+47 23 25 19 00</td>
          </tr>
          <tr>
            <td className={classes.subTd}>Portugal</td>
            <td className={classes.subTd}>ClientesPortugal@bestseller.com</td>
            <td className={classes.subTd}>+34 952 055 000</td>
          </tr>
          <tr>
            <td className={classes.subTd}>Spain</td>
            <td className={classes.subTd}>ClientesEspana@bestseller.com</td>
            <td className={classes.subTd}>+34 952 055 000</td>
          </tr>
          <tr>
            <td className={classes.subTd}>Sweden</td>
            <td className={classes.subTd}>ekonomi-sverige@bestseller.com</td>
            <td className={classes.subTd}>+46 8 585 550 00</td>
          </tr>
          <tr>
            <td className={classes.subTd}>Switzerland</td>
            <td className={classes.subTd}>debtor-schweiz@bestseller.com</td>
            <td className={classes.subTd}>+41 044 809 22 22</td>
          </tr>
          <tr>
            <td className={classes.subTd}>Türkiye</td>
            <td className={classes.subTd}>destek@bestseller.com</td>
            <td className={classes.subTd}></td>
          </tr>
          <tr>
            <td className={classes.subTd}>UAE</td>
            <td className={classes.subTd}>finance-at@bestseller.com</td>
            <td className={classes.subTd}>+43 1 4407434 4900</td>
          </tr>
          <tr>
            <td className={classes.subTd}>United Kingdom</td>
            <td className={classes.subTd}>creditcontroluk@bestseller.com</td>
            <td className={classes.subTd}>+44 20 7650 2048</td>
          </tr>
          <tr>
            <td className={classes.subTd}>USA</td>
            <td className={classes.subTd}>CSUSA@bestseller.com</td>
            <td className={classes.subTd}>+1 646 809 3303</td>
          </tr>
        </tbody>
      </table>
      <div>...</div>
    </div>
  );
};

export default PageNotAvailable;
