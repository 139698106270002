import React from "react";
import { useDispatch, useSelector } from "react-redux";
import MDTextField from "../../UI/MDTextField/MDTextField";
import { SigningAction } from "../../_types_/actions/SigningAction";
import {
  setRecipientCallingCode,
  setRecipientEmail,
  setRecipientName,
  setRecipientPhonenumber,
} from "../../_reducers_/SigningSlice";
import MDPhoneNumberField from "../../UI/MDPhoneNumberField/MDPhoneNumberField";
import { isNullOrUndefined } from "../../utils/CommonUtils";
import MDEmailField from "../../UI/MDEmailField/MDEmailField";

interface RecipientsProps {
  control: any;
  errors: any;
  formState: any;
  setValue: any;
  trigger: any;
}

const Recipients: React.FC<RecipientsProps> = ({ control, errors, formState, setValue, trigger }) => {
  // Your component logic here
  const dispatch = useDispatch();
  const signing = useSelector((state: { signing: SigningAction }) => state.signing);

  return (
    <div>
      {signing?.docuSignEnvelope?.docuSignRecipients.map((dsr, i) => (
        <div key={dsr.recipientId}>
          <h2>
            {dsr.roleName.toLocaleUpperCase() === "CUSTOMERSIGNER"
              ? "Customer Signer"
              : dsr.roleName.toLocaleUpperCase() === "BSSIGNER"
              ? "BESTSELLER Signer"
              : "Error"}
          </h2>

          <MDTextField
            label={"Name"}
            name={`recipients[${i}].name`}
            error={errors.recipients ? errors.recipients[i]?.name : undefined}
            control={control}
            rules={{
              required: {
                message: "Name is required",
                value: true,
              },
            }}
            value={dsr.name}
            onChangeSetValue={(e: string): void => {
              dispatch(
                setRecipientName({
                  name: e,
                  recipientId: dsr.recipientId,
                })
              );
            }}
            disabled={dsr.recipientSigningStatus === "completed"}
          />
          <MDEmailField
            emailLabel={"Email"}
            emailName={`recipients[${i}].email`}
            emailError={errors.recipients ? errors.recipients[i]?.email : undefined}
            control={control}
            emailValue={dsr.email}
            setValue={setValue}
            emailOnChange={(e: string): void => {
              dispatch(
                setRecipientEmail({
                  email: e,
                  recipientId: dsr.recipientId,
                })
              );
            }}
            disabled={dsr.recipientSigningStatus === "completed"}
          />
          {isNullOrUndefined(dsr.phoneNumber) ? undefined : (
            <MDPhoneNumberField
              callingCodeError={errors.recipients ? errors.recipients[i]?.callingCode : undefined}
              callingCodeOnChange={(e: string): void => {
                dispatch(
                  setRecipientCallingCode({
                    phoneNumberCallingCode: e,
                    recipientId: dsr.recipientId,
                  })
                );
              }}
              callingCodeName={`recipients[${i}].callingCode`}
              callingCodeValue={dsr.phoneNumberCallingCode}
              control={control}
              formState={formState}
              phoneNumberError={errors.recipients ? errors.recipients[i]?.phoneNumber : undefined}
              phoneNumberLabel="Phone number"
              phoneNumberName={`recipients[${i}].phoneNumber`}
              phoneNumberOnChange={(e: string): void => {
                dispatch(
                  setRecipientPhonenumber({
                    phoneNumber: e,
                    recipientId: dsr.recipientId,
                  })
                );
              }}
              phoneNumberRequired={true}
              phoneNumberValue={dsr.phoneNumber}
              setValue={setValue}
              trigger={trigger}
              disabled={dsr.recipientSigningStatus === "completed"}
            />
          )}
          {dsr.recipientSigningStatus === "completed" ? <div>Signing completed</div> : undefined}
        </div>
      ))}
    </div>
  );
};

export default Recipients;
