import classes from "./BottomBar.module.scss";

const BottomBar = (): React.ReactElement => {
  return (
    <>
      <div className={classes.spacing}></div>
      <footer></footer>
    </>
  );
};

export default BottomBar;
