import React, { ReactElement, useEffect, useState } from "react";
import { AllProductLinesType } from "../../_types_/AllProductLinesType";
import { BrandType } from "../../_types_/BrandType";
import MDDropDown, { DropDownItem } from "../MDDropDown/MDDropDown";

interface PropType {
  value: string;
  onChange: (value: any) => void;
  name: string;
  label: string;
  error: any;
  control: any;
  items: any;
  className: any;
  defaultMenuItem: boolean;
  defaultMenuItemKey: string;
  defaultMenuItemValue: string;
  chosenBrand?: AllProductLinesType | undefined;
  selectedProductLines?: BrandType | undefined;
}

const MDProductLineDropDown = (props: PropType): ReactElement<PropType> => {
  const [dropDownElements, setDropDownElements] = useState<DropDownItem[]>([
    { code: "0", value: "Error" },
  ]);

  useEffect(() => {
    const productLineIsAlreadyAdded = (productLineCode: string): boolean => {
      return props.selectedProductLines
        ? props.selectedProductLines.productLines
          ? props.selectedProductLines.productLines.findIndex(
              (pl) => pl.code === productLineCode
            ) !== -1
          : false
        : false;
    };

    const convertDropDownItemsType = () => {
      let tempDropDownArray: DropDownItem[] = [];

      if (props.chosenBrand) {
        props.chosenBrand.productLines.forEach((brand) => {
          if (!productLineIsAlreadyAdded(brand.code)) {
            tempDropDownArray.push({
              code: brand.code,
              value: brand.name,
            });
          }
        });
      }

      setDropDownElements(tempDropDownArray);
    };

    convertDropDownItemsType();
  }, [props.selectedProductLines, props.chosenBrand]);

  return (
    <MDDropDown
      label={props.label}
      name={props.name}
      error={props.error}
      control={props.control}
      value={props.value}
      items={dropDownElements}
      className={props.className}
      onChangeSetValue={props.onChange}
      defaultMenuItem={props.defaultMenuItem}
      defaultMenuItemKey={props.defaultMenuItemKey}
      defaultMenuItemValue={props.defaultMenuItemValue}
      validationText={props.defaultMenuItemValue}
    />
  );
};

export default MDProductLineDropDown;
