import React, { ReactElement } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FileCardAction } from "../../_types_/actions/FileCardAction";
import MDCheckBox from "../../UI/MDCheckBox/MDCheckBox";
import { useTranslation } from "react-i18next";
import { setPaymentTermAccepted } from "../../_reducers_/FileCardSlice";

interface PropTypes {
  errors: any;
  control: any;
  disabled?: boolean;
}

const PaymentTermsAccept = (props: PropTypes): ReactElement<PropTypes> => {
  const { t } = useTranslation("general");
  const fileCard = useSelector((state: { fileCard: FileCardAction }) => state.fileCard);
  const dispatch = useDispatch();

  return (
    <div>
      {fileCard.fileCard.paymentTermAccepted?.map((pta, index) => (
        <div key={index}>
          <MDCheckBox
            disabled={props.disabled}
            value={fileCard.fileCard.paymentTermAccepted ? fileCard.fileCard.paymentTermAccepted[index].accepted : ""}
            name={`paymentTermAccepted[${index}]`}
            error={props.errors.paymentTermAccepted ? props.errors.paymentTermAccepted[index] : undefined}
            control={props.control}
            optional={true}
            key={pta.paymentTermCode}
            onChangeSetValue={(event) => {
              dispatch(
                setPaymentTermAccepted({
                  accepted: event.target.checked,
                  paymentTermCode: fileCard.fileCard.paymentTermAccepted
                    ? fileCard.fileCard.paymentTermAccepted[index].paymentTermCode
                    : "-2",
                })
              );
            }}
            vAlignLabelTop={true}
            label={
              <>
                {t(
                  "paymentTermAccepted." +
                    (fileCard.fileCard.paymentTermAccepted
                      ? fileCard.fileCard.paymentTermAccepted[index].paymentTermCode
                      : "")
                )}
              </>
            }
          />
        </div>
      ))}
    </div>
  );
};

export default PaymentTermsAccept;
